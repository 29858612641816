import { ElementData } from '@wix/platform-editor-sdk';
import { COMPONENT_IDS, STATES } from '../../../constants';
import { ILocaleKeys } from '../../../locale-keys/LocaleKeys';
import { getRole } from '../../../utils/utils';
import { ElementsData } from '../../editor.app.types';

export const getAccessRestrictedElementsData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.accessRestricted.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.TITLE),
      },
    },
    {
      label: t.app.widgets.accessRestricted.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.accessRestricted.elements.label.button(),
      index: 2,
      identifier: {
        role: getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.BACK_BUTTON),
      },
    },
    {
      label: t.app.widgets.accessRestricted.elements.label.switchAccount(),
      tooltipData: {
        content:
          t.app.widgets.accessRestricted.elements.label.switchAccountTooltip(),
      },
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.SWITCH_ACCOUNT_BUTTON),
      },
    },
  ] as ElementData[];
  return { data, categories };
};

export const getResetPasswordElementsCreatePasswordStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.resetPassword.createPassword.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.TITLE),
      },
    },
    {
      label:
        t.app.widgets.resetPassword.createPassword.elements.label.passwordInput(),
      index: 1,
      identifier: {
        role: getRole(
          COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.PASSWORD_INPUT,
        ),
      },
    },
    {
      label:
        t.app.widgets.resetPassword.createPassword.elements.label.confirmPasswordInput(),
      index: 3,
      identifier: {
        role: getRole(
          COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP
            .CONFIRM_PASSWORD_INPUT,
        ),
      },
    },
    {
      label: t.app.widgets.resetPassword.createPassword.elements.label.button(),
      index: 5,
      identifier: {
        role: getRole(
          COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON,
        ),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getResetPasswordElementsSuccessStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.resetPassword.success.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.TITLE),
      },
    },
    {
      label: t.app.widgets.resetPassword.success.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.resetPassword.success.elements.label.primaryButton(),
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.PRIMARY_BUTTON),
      },
    },
    {
      label:
        t.app.widgets.resetPassword.success.elements.label.secondaryButton(),
      index: 4,
      identifier: {
        role: getRole(
          COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.SECONDARY_BUTTON,
        ),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getResetPasswordElementsDataByState = (state, t): ElementsData => {
  switch (state) {
    case STATES.RESET_PASSWORD.SUCCESS:
      return getResetPasswordElementsSuccessStateData(t);
    case STATES.RESET_PASSWORD.ERROR:
      const elementsData = getResetPasswordElementsCreatePasswordStateData(t);
      elementsData.data.splice(6, 0, {
        label:
          t.app.widgets.resetPassword.createPassword.elements.label.serverErrorMessage(),
        index: 6,
        identifier: {
          role: getRole(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
          ),
        },
      });
      return elementsData;
    default:
      return getResetPasswordElementsCreatePasswordStateData(t);
  }
};

export const getForgotPasswordElementsDataByState = (
  state,
  t,
): ElementsData => {
  switch (state) {
    case STATES.FORGOT_PASSWORD.LINK_SENT:
      return getForgotPasswordElementsLinkStateData(t);
    case STATES.FORGOT_PASSWORD.ERROR:
      return getForgotPasswordElementsErrorStateData(t);
    default:
      return getForgotPasswordElementsResetStateData(t);
  }
};

export const getForgotPasswordElementsResetStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.forgotPassword.emailStep.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.TITLE),
      },
    },
    {
      label: t.app.widgets.forgotPassword.emailStep.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.forgotPassword.emailStep.elements.label.emailInput(),
      index: 2,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.EMAIL_INPUT),
      },
    },
    {
      label: t.app.widgets.forgotPassword.emailStep.elements.label.button(),
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON),
      },
    },
    {
      label: t.app.widgets.forgotPassword.elements.label.closeButton(),
      index: 4,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.CLOSE_BUTTON),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getForgotPasswordElementsErrorStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = getForgotPasswordElementsResetStateData(t).data;
  data.push({
    label: t.app.widgets.forgotPassword.linkSent.elements.label.errorMessage(),
    index: 4,
    identifier: {
      role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR),
    },
  });

  return { data, categories };
};

export const getForgotPasswordElementsLinkStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.forgotPassword.linkSent.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.TITLE),
      },
    },
    {
      label: t.app.widgets.forgotPassword.linkSent.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.forgotPassword.linkSent.elements.label.memberEmail(),
      index: 2,
      tooltipData: {
        content:
          t.app.widgets.forgotPassword.linkSent.elements.label.memberEmailTooltip(),
      },
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.EMAIL),
      },
    },
    {
      label: t.app.widgets.forgotPassword.linkSent.elements.label.button(),
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.GOT_IT_BUTTON),
      },
    },
    {
      label: t.app.widgets.forgotPassword.linkSent.elements.label.checkSpam(),
      index: 4,
      identifier: {
        role: getRole(
          COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.DIDNT_GET_EMAIL_INFO,
        ),
      },
    },
    {
      label: t.app.widgets.forgotPassword.elements.label.closeButton(),
      index: 5,
      identifier: {
        role: getRole(COMPONENT_IDS.FORGOT_PASSWORD.CLOSE_BUTTON),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getExpiredTokenElementsData = (t: ILocaleKeys): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.expiredToken.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.EXPIRED_TOKEN.TITLE),
      },
    },
    {
      label: t.app.widgets.expiredToken.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.EXPIRED_TOKEN.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.expiredToken.elements.label.button(),
      index: 2,
      tooltipData: {
        content: t.app.widgets.expiredToken.elements.label.buttonTooltip(),
      },
      identifier: {
        role: getRole(COMPONENT_IDS.EXPIRED_TOKEN.PRIMARY_BUTTON),
      },
    },
    {
      label: t.app.widgets.expiredToken.elements.label.secondaryButton(),
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.EXPIRED_TOKEN.SECONDARY_BUTTON),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getEmailConfirmationElementsDataByState = (
  state,
  t,
): ElementsData => {
  switch (state) {
    case STATES.EMAIL_CONFIRMATION.ERROR:
      return getEmailConfirmationErrorStateData(t);
    case STATES.EMAIL_CONFIRMATION.WARNING:
      return getEmailConfirmationWarningStateData(t);
    case STATES.EMAIL_CONFIRMATION.CODE_RESEND_TIME:
      return getEmailConfirmationCodeResendTimeStateData(t);
    default:
      return getEmailConfirmationElementsData(t);
  }
};

export const getEmailConfirmationElementsData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.emailConfirmation.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.TITLE),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.memberEmail(),
      tooltipData: {
        content:
          t.app.widgets.emailConfirmation.elements.label.memberEmailTooltip(),
      },
      index: 2,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.EMAIL),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.emailInput(),
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.OTP_INPUT),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.button(),
      index: 4,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.PRIMARY_BUTTON),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.didntGetEmail(),
      index: 5,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TITLE),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.resendLink(),
      tooltipData: {
        content:
          t.app.widgets.emailConfirmation.elements.label.resendLinkTooltip(),
      },
      index: 6,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TEXT),
      },
    },
    {
      label: t.app.widgets.emailConfirmation.elements.label.closeButton(),
      index: 8,
      identifier: {
        role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.CLOSE_BUTTON),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getEmailConfirmationErrorStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = getEmailConfirmationElementsData(t).data;
  data.push({
    label: t.app.widgets.emailConfirmation.elements.label.serverErrorMessage(),
    index: 7,
    identifier: {
      role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.SERVER_ERROR),
    },
  });

  return { data, categories };
};

export const getEmailConfirmationWarningStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = getEmailConfirmationElementsData(t).data;
  data.push({
    label: t.app.widgets.emailConfirmation.elements.label.warningMessage(),
    index: 7,
    identifier: {
      role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.WARNING_NOTIFICATION),
    },
  });

  return { data, categories };
};

export const getEmailConfirmationCodeResendTimeStateData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = getEmailConfirmationElementsData(t).data;
  data.push({
    label: t.app.widgets.emailConfirmation.elements.label.resendTimer(),
    index: 7,
    identifier: {
      role: getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TIMER),
    },
  });

  return { data, categories };
};

export const getAdminApprovalElementsData = (t: ILocaleKeys): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.app.widgets.pendingApproval.elements.label.title(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.ADMIN_APPROVAL.TITLE),
      },
    },
    {
      label: t.app.widgets.pendingApproval.elements.label.subtitle(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.ADMIN_APPROVAL.SUB_TITLE),
      },
    },
    {
      label: t.app.widgets.pendingApproval.elements.label.email(),
      index: 2,
      identifier: {
        role: getRole(COMPONENT_IDS.ADMIN_APPROVAL.EMAIL),
      },
      tooltipData: {
        content: t.app.widgets.pendingApproval.elements.label.emailTooltip(),
      },
    },
    {
      label: t.app.widgets.pendingApproval.elements.label.description(),
      index: 3,
      identifier: {
        role: getRole(COMPONENT_IDS.ADMIN_APPROVAL.DESCRIPTION),
      },
    },
    {
      label: t.app.widgets.pendingApproval.elements.label.button(),
      index: 4,
      identifier: {
        role: getRole(COMPONENT_IDS.ADMIN_APPROVAL.PRIMARY_BUTTON),
      },
    },
    {
      label: t.app.widgets.pendingApproval.elements.label.closeButton(),
      index: 5,
      identifier: {
        role: getRole(COMPONENT_IDS.ADMIN_APPROVAL.CLOSE_BUTTON),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getErrorMessagesElementsData = (t: ILocaleKeys): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.elements.labels.errorIcon(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.ERROR_MESSAGE.ICON),
      },
    },
    {
      label: t.elements.labels.errorMessage(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.ERROR_MESSAGE.TEXT),
      },
    },
  ] as ElementData[];

  return { data, categories };
};

export const getWarningMessagesElementsData = (
  t: ILocaleKeys,
): ElementsData => {
  const categories = [];
  const data = [
    {
      label: t.elements.labels.warningIcon(),
      index: 0,
      identifier: {
        role: getRole(COMPONENT_IDS.WARNING_MESSAGE.ICON),
      },
    },
    {
      label: t.elements.labels.warningMessage(),
      index: 1,
      identifier: {
        role: getRole(COMPONENT_IDS.WARNING_MESSAGE.TEXT),
      },
    },
  ] as ElementData[];

  return { data, categories };
};
