import { WidgetBuilder } from '@wix/app-manifest-builder';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { accessRestrictedDesignBuilderFn } from './accessRestricted.widget.design';
import { getRole } from '../../utils/utils';

export const setAccessRestrictedWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  helpId: string,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName: t.app.widgets.displayNames.accessRestricted(),
    nickname: 'accessRestricted',
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.app.widgets.gfpp.managePagePermissions(),
      actionType: 'settings',
      actionId: CUSTOM_ACTIONS_IDS.MANAGE_PAGE_PERMISSIONS,
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('layout', { behavior: 'DEFAULT' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      actionId: CUSTOM_ACTIONS_IDS.ACCESS_RESTRICTEDS_SHOW_HIDE_ELEMENTS,
    })
    .set('design', { behavior: 'DEFAULT' })
    .set('stretch', { behavior: 'HIDE' })
    .set('help', { id: helpId });

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('focalPoint', { behavior: 'HIDE' })
    .set('upgrade', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('hide', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: helpId });

  widgetBuilder.configureConnectedComponents(
    'box1',
    (connectedComponetBuilder) => {
      connectedComponetBuilder.behavior().set({ preventHide: true });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.BACK_BUTTON),
    (connectedComponetBuilder) => {
      connectedComponetBuilder
        .gfpp()
        .set('link', { behavior: 'HIDE' })
        .set('animation', { behavior: 'DEFAULT' });

      connectedComponetBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        })
        .configureStates({});
    },
  );

  widgetBuilder.configureWidgetDesign((wb) =>
    accessRestrictedDesignBuilderFn(wb, t),
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.SWITCH_ACCOUNT_BUTTON),
    (connectedComponetBuilder) => {
      connectedComponetBuilder.set({
        displayName:
          t.app.components.accessRestricted.displayNames.switchAccount(),
      });
      connectedComponetBuilder
        .gfpp()
        .set('link', { behavior: 'HIDE' })
        .set('animation', { behavior: 'DEFAULT' });

      connectedComponetBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.TITLE),
    (connectedComponetBuilder) => {
      connectedComponetBuilder.set({
        displayName: t.app.components.accessRestricted.displayNames.title(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.SUB_TITLE),
    (connectedComponetBuilder) => {
      connectedComponetBuilder.set({
        displayName: t.app.components.accessRestricted.displayNames.subtitle(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ACCESS_RESTRICTEDS.BACK_BUTTON),
    (connectedComponetBuilder) => {
      connectedComponetBuilder.set({
        displayName:
          t.app.components.accessRestricted.displayNames.goHomeButton(),
      });
      connectedComponetBuilder
        .gfpp()
        .set('link', { behavior: 'HIDE' })
        .set('animation', { behavior: 'DEFAULT' });
      connectedComponetBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    essential: {
      enabled: false,
    },
  });
};
