import { siteMembersAuthenticationPagesAppInstalledSrc5Evid1731 } from '@wix/bi-logger-identity-data/v2';
import type { GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { installer } from './appInstaller';
import { APP_TOKEN } from './constants';
import { getAppManifest as getAppManifestWrapper } from './editor-app/appManifest';
import { disablePopupsAutoOpen } from './editor.utils';
import { configureEvents } from './events';
import { initLocaleKeys } from './initLocaleKeys';

const DSN = 'https://aa9fa2b0e52c4f068c782c8f69a39057@sentry.wixpress.com/292';
const configuration = { dataCallback: (data: any) => data };

export const editorReady = async (editorSDK, appDefId, options, flowAPI) => {
  const fedOpsLogger = options.monitoring.createFedopsLogger()('sm-blocks-app');
  fedOpsLogger.appLoadStarted();
  const sentryMonitor = options.monitoring.createSentryMonitorForApp(
    DSN,
    configuration,
  );
  const t = initLocaleKeys(flowAPI.translations.i18n);
  try {
    const isResponsive = options.origin.type === 'RESPONSIVE';
    const isStudio = isResponsive || options.origin.subType === 'STUDIO';

    if (options.firstInstall) {
      fedOpsLogger.interactionStarted('install');
      await installer(t, flowAPI)(
        editorSDK,
        APP_TOKEN,
        appDefId,
        isStudio,
        isResponsive,
      );
      fedOpsLogger.interactionEnded('install');
      const msid = await editorSDK?.info.getMetaSiteId(APP_TOKEN);
      flowAPI.bi?.report(
        siteMembersAuthenticationPagesAppInstalledSrc5Evid1731({
          is_success: true,
          app_id: appDefId,
          meta_site_id: msid,
        }),
      );

      const pageRef = await editorSDK.pages.getCurrent();
      await editorSDK.editor.openPagesPanel('', {
        pageRef,
        initialSettingsTabType: editorSDK.editor.PagesPanelTabType.Permissions,
        renameEnabled: false,
      });
      editorSDK.editor.showNotification('', {
        message: t.app.installation.notification.$value(),
        // link: {
        //   caption: t.app.installation.notification.learnMore(),
        // },
        type: 'info',
      });
    }

    await disablePopupsAutoOpen(editorSDK);
    configureEvents(editorSDK, flowAPI, isResponsive);

    // Snippet code for merging the custom login into the app menu
    // const publicApi = await editorSDK.document.application.getPublicAPI(
    //   'token',
    //   { appDefinitionId: 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f' },
    // );
    // await publicApi?.addPopupsToAppById?.(appDefId);
  } catch (e: any) {
    const msid = await editorSDK?.info.getMetaSiteId(APP_TOKEN);
    flowAPI.bi?.report(
      siteMembersAuthenticationPagesAppInstalledSrc5Evid1731({
        is_success: false,
        app_id: appDefId,
        meta_site_id: msid,
        error: e.message,
      }),
    );
    sentryMonitor.captureException(e);
  }

  fedOpsLogger.appLoaded();
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
) => {
  return getAppManifestWrapper(options, editorSDK, contextParams, flowAPI);
};
