import { EditorSDK, RouterRef } from '@wix/platform-editor-sdk';
import app from '../../.application.json';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APP_TOKEN } from '../constants';

export const initAuthRouterPrefix = async (
  editorSDK: FlowEditorSDK,
  _prefix: string,
  flowAPI: EditorScriptFlowAPI,
): Promise<RouterRef> => {
  let routerRef;
  let prefix = _prefix;
  let config;
  try {
    const portfolioPagesData = await editorSDK.pages.getApplicationPages(
      APP_TOKEN,
    );
    let checkValidity = true;
    let i = 1;
    while (checkValidity) {
      const isValidPrefix = !(
        await editorSDK.routers.isValidPrefix(APP_TOKEN, { prefix })
      ).valid;
      const existingRouter = !!(await editorSDK.routers.getByPrefix(APP_TOKEN, {
        prefix,
      }));
      checkValidity = isValidPrefix || existingRouter;
      if (checkValidity) {
        prefix = _prefix + i;
        i++;
      } else {
        checkValidity = false;
      }
    }
    config = { portfolioPagesData };
    routerRef = await editorSDK.routers.add(APP_TOKEN, {
      prefix,
      config,
    });
    return routerRef;
  } catch (error: any) {
    flowAPI.errorMonitor.captureException(error, {
      tags: {
        actionName: 'initAuthRouterPrefix',
        prefix,
        routerRef,
        config,
      },
    });
    throw error;
  }
};
