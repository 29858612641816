export const VeloTemplate = `
import { authentication } from "wix-members-frontend";
import wixWindowFrontend from "wix-window-frontend";

$w.onReady(function () {
	$w('#createPasswordBtn').onClick(() => {
		const newPassword = $w('#password').value;
		const confirmPassword = $w('#confirmPassword').value;

		if (newPassword === confirmPassword) {
			const appData = wixWindowFrontend.getAppPageData();
			const token = appData?.resetPasswordToken;
			authentication
			.changePassword(newPassword, token)
			.then(() => {
				console.log("Password changed successfully");
			})
			.catch((error) => {
				console.error("Error changing password:", error);
			});
		}
	})
});`;
