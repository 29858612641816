import { PageDefinition } from '@wix/platform-editor-sdk';
import { ResponsiveSize } from './lightbox';

export const responsiveContainerComponent = (
  responsiveSize: ResponsiveSize = {
    width: {
      type: 'percentage',
      value: 100,
    },
    height: {
      type: 'percentage',
      value: 100,
    },
  },
  replacer?: PageDefinition,
) => [
  {
    type: 'Container',
    componentType: 'wysiwyg.viewer.components.PopupContainer',
    skin: 'wysiwyg.viewer.skins.screenwidthcontainer.ResponsivePopupContainerWithColorBG',
    layout: {
      x: 620,
      y: 80,
      fixedPosition: false,
      width: 740,
      height: 360,
      scale: 1,
      rotationInDegrees: 0,
    },
    components: replacer,
    metaData: {
      pageId: 'x8d5b',
      sig: 'tp3-70',
    },
    parent: 'x8d5b',
    props: {
      type: 'PopupContainerProperties',
      horizontalAlignment: 'center',
      verticalAlignment: 'center',
      alignmentType: 'nineGrid',
      horizontalOffset: 0,
      verticalOffset: 0,
      metaData: {
        schemaVersion: '1.0',
        pageId: 'x8d5b',
        sig: 'tp3-35',
      },
    },
    design: {
      type: 'MediaContainerDesignData',
      background: {
        type: 'BackgroundMedia',
        color: '#FFFFFE',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        metaData: {
          schemaVersion: '1.0',
          isPreset: false,
          isHidden: false,
          pageId: 'x8d5b',
          sig: 'tp3-36',
        },
      },
      metaData: {
        schemaVersion: '1.0',
        isPreset: false,
        isHidden: false,
        pageId: 'x8d5b',
        sig: 'tp3-37',
      },
    },
    connections: {
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'lightbox1',
        },
        {
          type: 'ConnectionItem',
          role: 'popupContainer_forgotPasswordTPAReplacer',
          isPrimary: true,
          config: '{"properties":{}}',
          controllerId: 'dataItem-m7t0l04h',
        },
      ],
      type: 'ConnectionList',
      metaData: {
        schemaVersion: '1.0',
        isPreset: false,
        isHidden: false,
        pageId: 'x8d5b',
        sig: 'tp3-74',
      },
    },
    scopedLayouts: {},
    layouts: {
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        overflowY: 'hidden',
        overflowX: 'hidden',
      },
      spx: {
        refWidth: 1200,
        resolverType: 'scale',
      },
      componentLayout: {
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 100,
        },
        maxWidth: {
          type: 'px',
          value: responsiveSize.width.value,
        },
      },
      itemLayout: {
        type: 'FixedItemLayout',
        margins: {
          left: {
            type: 'px',
            value: 0,
          },
          right: {
            type: 'px',
            value: 0,
          },
          top: {
            type: 'px',
            value: 0,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
        justifySelf: 'center',
        alignSelf: 'center',
      },
      type: 'SingleLayoutData',
    },
    style: {
      type: 'ComponentStyle',
      style: {
        properties: {
          'alpha-bg': '1',
          bg: 'color_11',
        },
        propertiesSource: {
          'alpha-bg': 'value',
          bg: 'theme',
        },
        groups: {},
      },
      componentClassName: 'wysiwyg.viewer.components.PopupContainer',
      pageId: '',
      compId: '',
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.screenwidthcontainer.ResponsivePopupContainerWithColorBG',
      metaData: {
        schemaVersion: '1.0',
        isPreset: false,
        isHidden: false,
        pageId: 'x8d5b',
        sig: 'tp3-40',
      },
    },
    activeModes: {},
    referredVariants: {},
    activeVariants: [],
    siteWidth: 980,
  },
];

export const classicContainerComponent = (
  width: number,
  replacer?: PageDefinition,
) => [
  {
    type: 'Container',
    componentType: 'wysiwyg.viewer.components.PopupContainer',
    skin: 'wysiwyg.viewer.skins.screenwidthcontainer.ResponsivePopupContainerWithColorBG',
    layout: {
      width: width + 40,
      height: 360,
      x: 0,
      y: 0,
    },
    mobileHints: {
      type: 'MobileHints',
      recommendedWidth: 320,
    },
    layouts: undefined,
    props: {
      type: 'PopupContainerProperties',
    },
    design: {
      type: 'MediaContainerDesignData',
      background: {
        fittingType: 'fill',
        alignType: 'center',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        color: '#FFFFFE',
        colorOpacity: 1,
        type: 'BackgroundMedia',
        metaData: {
          schemaVersion: '1.0',
          isPreset: false,
          isHidden: false,
        },
        scrollType: 'none',
        colorLayers: [
          {
            type: 'SolidColorLayer',
            opacity: 0,
            fill: {
              type: 'SolidColor',
              color: '#FFFFFE',
            },
            metaData: {
              schemaVersion: '1.0',
              isPreset: false,
              isHidden: false,
            },
          },
        ],
      },
    },
    components: replacer,
  },
];
