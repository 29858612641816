import { COMPONENT_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { WidgetDesignBuilderFn } from '../../editor-app/editor.app.types';
import { getRole } from '../../utils/utils';

export const resetPasswordDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t: ILocaleKeys,
): void => {
  widgetDesignBuilder.set({
    title: t.app.widgets.resetPassword.design.title(),
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.title(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.TITLE,
            COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.TITLE,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.subtitle(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.SUB_TITLE].map(
            getRole,
          ),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.errorMessages(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ERROR_MESSAGE.TEXT].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.inputs(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.PASSWORD_INPUT,
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP
              .CONFIRM_PASSWORD_INPUT,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.mainButtons(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON,
            COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.PRIMARY_BUTTON,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.linkButton(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.SECONDARY_BUTTON,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.resetPassword.design.tabs.background(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.CONTAINER,
            COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.CONTAINER,
          ].map(getRole),
        });
    });
};
