import type {
  ComponentRef,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import application from '../../.application.json';
import {
  APP_TOKEN,
  IScopedPreset,
  LIGHTBOX_CONNECTED_PARAMS,
} from '../constants';
import { toScopedPreset } from '../utils/utils';
import { ILocaleKeys } from '../locale-keys/LocaleKeys';
import {
  classicContainerComponent,
  responsiveContainerComponent,
} from './lightboxComponents';

const TOKEN = 'token';

export type ResponsiveSize = {
  width: {
    type: 'px' | 'percentage';
    value: number;
  };
  height: {
    type: 'px' | 'percentage' | 'auto';
    value: number;
  };
};

const widgetLayouts = (
  responsiveSize: ResponsiveSize = {
    width: {
      type: 'percentage',
      value: 100,
    },
    height: {
      type: 'percentage',
      value: 100,
    },
  },
) =>
  ({
    containerLayout: {},
    spx: {
      refWidth: 1200,
      resolverType: 'scale',
    },
    componentLayout: {
      hidden: false,
      height: {
        type: 'auto',
      },
      type: 'ComponentLayout',
      width: {
        type: 'percentage',
        value: 100,
      },
      maxWidth: {
        type: 'px',
        value: 700,
      },
    },
    itemLayout: {
      type: 'FixedItemLayout',
      justifySelf: 'stretch',
      alignSelf: 'stretch',
    },
    type: 'SingleLayoutData',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

export const installLightbox = async ({
  editorSDK,
  title,
  tpaPageId,
  isResponsive,
  width = 700,
  responsiveSize,
  replacerComponents,
}: {
  editorSDK: FlowEditorSDK;
  title: string;
  tpaPageId: string;
  isResponsive: boolean;
  withoutLayout?: boolean;
  widgetId?: string;
  width?: number;
  responsiveSize?: ResponsiveSize;
  replacerComponents: any;
}) => {
  // fedopsLogger.installLightboxStarted();
  const popupRef = await editorSDK.document.pages.popupPages.addConnected(
    TOKEN,
    {
      title,
      shouldNavigateToPage: false,
      controllerType: `${LIGHTBOX_CONNECTED_PARAMS.CONTROLLER}_${tpaPageId}`,
      popupRole: `${LIGHTBOX_CONNECTED_PARAMS.ROLE}_${tpaPageId}`,
      definition: {
        props: {
          desktop: {
            popup: {
              closeOnOverlayClick: false,
            },
          },
          mobile: {
            popup: {
              closeOnOverlayClick: false,
            },
          },
          type: 'PageProperties',
        },
        data: {
          appDefinitionId: application.appDefinitionId,
          managingAppDefId: application.appDefinitionId,
          hidePage: false,
          tpaPageId,
          pageBackgrounds: {
            desktop: {
              custom: true,
              isPreset: false,
              ref: {
                type: 'BackgroundMedia',
                color: '#000',
                // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
                // @ts-ignore
                colorOpacity: 0.6,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0,
              },
            },
            mobile: {
              custom: true,
              isPreset: false,
              ref: {
                type: 'BackgroundMedia',
                color: '#000',
                // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
                // @ts-ignore
                colorOpacity: 0.6,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0,
              },
            },
          },
        },
        components: isResponsive
          ? (responsiveContainerComponent(
              responsiveSize,
              replacerComponents,
            ) as any)
          : (classicContainerComponent(width, replacerComponents) as any),
        style: {
          type: 'ComponentStyle',
          style: {
            properties: {
              'alpha-bg': '0.6',
              bg: 'color_15',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              bg: 'theme',
            },
            groups: {},
          },
          componentClassName: 'mobile.core.components.Page',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
          pageId: '',
          compId: '',
        },
      },
    },
  );

  const [lightboxComponent] = await editorSDK.document.components.getChildren(
    TOKEN,
    {
      componentRef: popupRef,
    },
  );
  return { lightboxComponent, popupRef };
};

export const addWidgetToLightbox = async ({
  editorSDK,
  lightboxRef,
  widgetId,
  scopedPresets,
  isResponsive,
  width = 700,
  responsiveSize,
}: {
  editorSDK: FlowEditorSDK;
  lightboxRef: ComponentRef;
  widgetId: string;
  scopedPresets: IScopedPreset;
  isResponsive: boolean;
  width?: number;
  responsiveSize?: ResponsiveSize;
}) => {
  const componentRef = await editorSDK.application.appStudioWidgets.addWidget(
    TOKEN,
    {
      widgetId,
      installationType: 'closed' as WidgetInstallationType,
      // NOTE: docked is being used to stretch the widget to the full width of the page

      layout: {
        fixedPosition: false,
        width,
        height: 320,
        x: 20,
        y: 20,
      },
      layouts: isResponsive ? widgetLayouts(responsiveSize) : undefined,
      scopedPresets: toScopedPreset(scopedPresets),
      containerRef: lightboxRef,
    },
  );

  const lightBoxWidgetLayout = await editorSDK.document.components.layout.get(
    TOKEN,
    {
      componentRef,
    },
  );

  console.log('lightBoxWidgetLayout - ', lightBoxWidgetLayout);
  // const msid = await editorSDK?.info.getMetaSiteId(APP_TOKEN);
};

const getReplacerComponents = (isStudio: boolean, componentName: string) => {
  try {
    return require(`../components/${componentName}/replacer/${
      isStudio ? 'studio' : 'classic'
    }`);
  } catch (e) {
    console.log('Error while getting replacer components:', e);
    return [];
  }
};

export const replaceLightboxWithAppReflow = async (
  editorSDK,
  t: ILocaleKeys,
  pageRef,
  isStudio: boolean,
  isResponsive: boolean,
  componentName: string,
) => {
  try {
    const pageData = await editorSDK.pages.getPageData(APP_TOKEN, { pageRef });
    const replacerComponents = getReplacerComponents(isStudio, componentName);
    const { title, tpaPageId } = pageData;
    const { popupRef: replacer } = await installLightbox({
      editorSDK,
      title: `${t.app.settings.replacer.custom()} ${title}`,
      isResponsive,
      tpaPageId: `${tpaPageId}Replacer`,
      replacerComponents,
      responsiveSize: {
        width: {
          type: 'px',
          value: 700,
        },
        height: {
          type: 'px',
          value: 500,
        },
      },
    });
    await editorSDK.editor.routers.setReplacerPage(APP_TOKEN, {
      pageRef,
      replacerPageRef: replacer,
      setAsActive: true,
    });
    // inject Velo code template to the replacer page
    const {
      VeloTemplate,
    } = require(`../components/${componentName}/replacer/veloTemplate`);
    await editorSDK.vfs.writeFile(APP_TOKEN, {
      path: `public/pages/${replacer.id}.js`,
      content: VeloTemplate,
    });
  } catch (e) {
    console.log('Error while creating replacer:', e);
  }
};
