import { COMPONENT_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { WidgetDesignBuilderFn } from '../../editor-app/editor.app.types';
import { getRole } from '../../utils/utils';

export const expiredTokenDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t: ILocaleKeys,
): void => {
  widgetDesignBuilder.set({
    title: t.app.widgets.expiredToken.design.title(),
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.expiredToken.design.tabs.title(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EXPIRED_TOKEN.TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.expiredToken.design.tabs.subtitle(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EXPIRED_TOKEN.SUB_TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.expiredToken.design.tabs.mainButtons(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EXPIRED_TOKEN.PRIMARY_BUTTON].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.expiredToken.design.tabs.linkButton(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EXPIRED_TOKEN.SECONDARY_BUTTON].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.expiredToken.design.tabs.background(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EXPIRED_TOKEN.CONTAINER].map(getRole),
        });
    });
};
