import { getRole } from '../../utils/utils';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS } from '../../constants';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';

export const setWarningMessageWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  helpId: string,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName:
      t.app.components.emailConfirmation.displayNames.warningMessage(),
    nickname: 'Warning msg Widget',
  });

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.WARNING_MESSAGE.CONTAINER),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
        toggleShowOnAllPagesEnabled: false,
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.WARNING_MESSAGE.ICON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        duplicatable: false,
        toggleShowOnAllPagesEnabled: false,
      });
      connectedComponentsBuilder
        .gfpp()
        .set('link', { behavior: 'HIDE' })
        .set('settings', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.WARNING_MESSAGE.TEXT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.emailConfirmation.displayNames.warningMessage(),
      });
      connectedComponentsBuilder.behavior().set({
        duplicatable: false,
        toggleShowOnAllPagesEnabled: false,
        preventHide: true,
        essential: {
          enabled: true,
        },
      });
      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', { behavior: 'HIDE' })
        .set('mainAction2', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('layout', { behavior: 'HIDE' })
        .set('animation', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' })
        .set('add', {
          behavior: 'HIDE',
        })
        .set('widgetPlugins', { behavior: 'HIDE' })
        .set('filters', { behavior: 'HIDE' })
        .set('focalPoint', { behavior: 'HIDE' })
        .set('crop', { behavior: 'HIDE' })
        .set('stretch', { behavior: 'HIDE' })
        .set('presets', { behavior: 'HIDE' })
        .set('pinMode', { behavior: 'HIDE' })
        .set('upgrade', { behavior: 'HIDE' })

        .set('design', { behavior: 'DEFAULT' });
    },
  );

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.app.widgets.gfpp.changeDesign(),
      actionType: 'design',
      actionId: '',
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      actionId: CUSTOM_ACTIONS_IDS.WARNING_MESSAGE_SHOW_HIDE_ELEMENTS,
    })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: helpId })
    .set('design', { behavior: 'DEFAULT' });

  widgetBuilder.behavior().set({
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    closed: {
      selectable: false,
      hideFromHierarchy: true,
    },
  });

  widgetBuilder.configureWidgetDesign((wb) => {
    wb.set({
      title: t.app.widgets.warningMessage.design.title(),
    });

    const widgetDesignTabsBuilder = wb.tabs();
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t.app.widgets.warningMessage.design.text(),
          })
          .groups()
          .set({
            roles: [COMPONENT_IDS.WARNING_MESSAGE.TEXT].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t.app.widgets.warningMessage.design.background(),
          })
          .groups()
          .set({
            roles: [COMPONENT_IDS.WARNING_MESSAGE.CONTAINER].map(getRole),
          });
      });
  });
};
