import {
  type addWidgetOptions,
  type ComponentRef,
  type EditorSDK,
  type PageRef,
  type SDKContext,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import { PageDefinitionData } from './editor.app.types';
import { toScopedPreset } from '../utils/utils';
import {
  addWidgetToLightbox,
  installLightbox,
  ResponsiveSize,
} from './lightbox';
import { createPage } from './page';
import app from '../../.application.json';

type PageProps = (
  editorSDK: EditorSDK,
  pageDefinitionData: PageDefinitionData,
) => Promise<{
  componentRef: ComponentRef;
  pageRef: PageRef;
  containerRef: ComponentRef;
}>;

export const installWidgetInPage: PageProps = async (
  editorSDK,
  pageDefinitionData,
) => {
  const { containerRef, pageRef } = await createPage(
    editorSDK,
    pageDefinitionData,
  );
  const componentRef = await editorSDK.application.appStudioWidgets.addWidget(
    '',
    {
      widgetId: pageDefinitionData.id,
      appDefinitionId: app.appDefinitionId,
      containerRef,
      scopedPresets: toScopedPreset(pageDefinitionData.scopedPreset),
      installationType: WidgetInstallationType.Closed,
      layout: {
        height: 400,
        width: 980,
        fixedPosition: false,
        x: 0,
        y: 50,
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 500,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'auto',
          },
        },
        itemLayout: {
          margins: {
            left: {
              type: 'percentage',
              value: 5,
            },
            right: {
              type: 'percentage',
              value: 5,
            },
            top: {
              type: 'px',
              value: 20,
            },
            bottom: {
              type: 'px',
              value: 20,
            },
          },
          alignSelf: 'stretch',
          justifySelf: 'stretch',
          gridArea: {
            rowStart: 1,
            columnStart: 1,
            rowEnd: 2,
            columnEnd: 2,
          },
          type: 'GridItemLayout',
        },
        type: 'SingleLayoutData',
        containerLayout: {
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
    } as addWidgetOptions<SDKContext>,
  );

  return { pageRef, containerRef, componentRef };
};

export const installWidgetInLightBox = async (
  editorSDK,
  lightboxDefinitionData,
) => {
  const { id, title, tpaPageId, scopedPreset, isResponsive, height } =
    lightboxDefinitionData;

  const responsiveSize: ResponsiveSize = {
    width: {
      type: 'px',
      value: 700,
    },
    height: {
      type: 'px',
      value: height ?? 500,
    },
  };

  const { lightboxComponent: lightboxRef, popupRef } = await installLightbox({
    editorSDK,
    widgetId: id,
    title,
    tpaPageId,
    isResponsive,
    responsiveSize,
    replacerComponents: [],
  });

  await addWidgetToLightbox({
    editorSDK,
    lightboxRef,
    widgetId: id,
    scopedPresets: scopedPreset,
    isResponsive,
    responsiveSize,
  });
  return { pageRef: popupRef, lightboxRef };
};
