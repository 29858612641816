export const VeloTemplate = `
import { authentication } from "wix-members-frontend";

$w.onReady(function () {
	$w('#submitBtn').onClick(()=> {
		if (!$w('#codeInput').valid) {
			return;
		}
		const code = $w('#codeInput').value;2
		authentication
		.verifyEmail(code)
		.then(() => {
			console.log("OTP verified");
		})
		.catch((error) => {
			console.error("Error verifying OTP:", error);
		});
	})
});
`;
