import { AppManifestBuilder } from '@wix/app-manifest-builder';
import {
  APP_TOKEN,
  COMPONENT_IDS,
  LIGHTBOX_CONNECTED_PARAMS,
} from '../constants';
import {
  AppManifest,
  ConnectedComponentConfig,
  GfppConfig,
  GfppDesktopConfig,
  WidgetStateStageData,
} from '@wix/platform-editor-sdk';
import emailConfirmationWidget from '../components/email-confirmation/.component.json';
import adminApproval from '../components/admin-approval/.component.json';
import acccessRestricted from '../components/access-restricted/.component.json';
import expiredToken from '../components/expired-token/.component.json';
import forgotPassword from '../components/forgot-password/.component.json';
import resetPassword from '../components/reset-password/.component.json';

export const toScopedPreset = (presets) => ({
  desktop: {
    layout: presets.desktop,
    style: presets.desktop,
  },
  mobile: {
    layout: presets.mobile,
    style: presets.mobile,
  },
});

export const getRole = (wixCodeId: string): string => {
  return wixCodeId.substring(1);
};

export const cleanAllPages = async (editorSDK, token, context, appDefId) => {
  try {
    const appPopups = (context.appPopups =
      await editorSDK.pages.popupPages.getApplicationPopups(APP_TOKEN));
    console.log('🚀 ~ appPopups:', appPopups);
    const appPages = await editorSDK.pages.getApplicationPages(APP_TOKEN);
    console.log('🚀 ~ appPages:', appPages);
    for (const x of appPages) {
      console.log('page - ', x.id);
      await editorSDK.pages.remove(APP_TOKEN, {
        pageRef: { id: x.id!, type: 'DESKTOP' },
        shouldShowEditorRemovePanel: false,
      });
    }
    for (const x of appPopups) {
      console.log('popup - ', x.id);
      await editorSDK.pages.popupPages.open(APP_TOKEN, {
        popupRef: { id: x.id!, type: 'DESKTOP' },
      });
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await editorSDK.pages.remove(APP_TOKEN, {
        pageRef: { id: x.id!, type: 'DESKTOP' },
        shouldShowEditorRemovePanel: false,
      });
    }
    await editorSDK.routers.remove('token', {
      routerRef: {
        id: 'auth',
      },
    });
    console.log('router removed');
  } catch (e) {
    console.log('🚀 ~ file: appInstaller.ts:39 ~ .withStep ~ e:', e);
  }
};

export const closePopup = ({ $w, window }) => {
  setTimeout(() => {
    console.log('🚀 ~ file: utils.ts:226 ~ closePopup ~ $w:', $w);
    console.log(
      "🚀 ~ file: utils.ts:226 ~ $w ~ $w('@closeIcon'):",
      $w('closeIcon'),
    );
  }, 2000);
  $w('@closeIcon')?.onClick?.(() => {
    window?.lightbox?.close?.();
  });
};

export const setLightboxGfpp = (
  appManifestBuilder: AppManifestBuilder,
  id: string,
  displayName?: string,
) => {
  appManifestBuilder.configureController(
    `${LIGHTBOX_CONNECTED_PARAMS.CONTROLLER}_${id}`,
    (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        `${LIGHTBOX_CONNECTED_PARAMS.ROLE}_${id}`,
        (componentBuilder) => {
          displayName && componentBuilder.set({ displayName });
          componentBuilder.behavior();

          componentBuilder
            .gfpp()
            .set('mainAction2', { behavior: 'HIDE' })
            .set('settings', { behavior: 'HIDE' });
        },
      );
    },
  );
};

const getControllerWidgetById = (appManifest: AppManifest, id: string) => {
  return appManifest.controllersStageData![id].default;
};

const getControllerWidgets = (
  appManifest: AppManifest,
  widgetControllerIds: string[],
) => {
  return widgetControllerIds.map((widgetId) =>
    getControllerWidgetById(appManifest, widgetId),
  );
};

export const setMobileGfpp = async (appManifest: AppManifest) => {
  const stageDataDefaultArr = getControllerWidgets(appManifest, [
    emailConfirmationWidget.controllerId,
    adminApproval.controllerId,
    acccessRestricted.controllerId,
    expiredToken.controllerId,
    forgotPassword.controllerId,
    resetPassword.controllerId,
  ]).filter((widget): widget is WidgetStateStageData => !!widget);
  addMobileDesign(stageDataDefaultArr);
};

const addMobileDesign = (
  widgetStateData: (WidgetStateStageData | ConnectedComponentConfig)[],
) => {
  widgetStateData.forEach((widget: WidgetStateStageData) => {
    widget.gfpp && addMobileGfpp(widget.gfpp, ['widgetDesign']);
    if (widget.connections) {
      const componentConfigs = Object.values(widget.connections);
      componentConfigs.forEach((config) => {
        config?.gfpp && addMobileGfpp(config.gfpp, ['widgetDesign']);
      });
    }
  });
};

const addMobileGfpp = (
  gfppConfig: GfppConfig,
  gfpps: (keyof GfppDesktopConfig)[],
) => {
  if (gfppConfig.desktop && gfppConfig.mobile) {
    gfpps.forEach((gfpp) => {
      // @ts-expect-error
      gfppConfig.mobile[gfpp] = {
        // @ts-expect-error
        ...(gfppConfig.desktop as GfppDesktopConfig)[gfpp],
      };
    });
  }
};
