import { COMPONENT_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { WidgetDesignBuilderFn } from '../../editor-app/editor.app.types';
import { getRole } from '../../utils/utils';

export const adminApprovalDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t: ILocaleKeys,
): void => {
  widgetDesignBuilder.set({
    title: t.app.widgets.pendingApproval.design.title(),
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.pendingApproval.design.tabs.title(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ADMIN_APPROVAL.TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.pendingApproval.design.tabs.subtitle(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.ADMIN_APPROVAL.SUB_TITLE,
            COMPONENT_IDS.ADMIN_APPROVAL.EMAIL,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.pendingApproval.design.tabs.description(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ADMIN_APPROVAL.DESCRIPTION].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.pendingApproval.design.tabs.mainButtons(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ADMIN_APPROVAL.PRIMARY_BUTTON].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.pendingApproval.design.tabs.background(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ADMIN_APPROVAL.CONTAINER].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.pendingApproval.design.tabs.closeButton(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ADMIN_APPROVAL.CLOSE_BUTTON].map(getRole),
        });
    });
};
