export const VeloTemplate = `
import { authentication } from "wix-members-frontend";

$w.onReady(function () {
	$w('#sendLinkBtn').onClick(()=> {
		const email = $w('#emailInput').value;
		authentication
		.sendResetPasswordEmail(email)
		.then((status) => {
			console.log("Password reset email sent");
		})
		.catch((error) => {
			console.error("Error sending email:", error);
		});
	});
});`;
