import { COMPONENT_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { WidgetDesignBuilderFn } from '../../editor-app/editor.app.types';
import { getRole } from '../../utils/utils';

export const forgotPasswordDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t: ILocaleKeys,
): void => {
  widgetDesignBuilder.set({
    title: t.app.widgets.forgotPassword.design.title(),
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.title(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.TITLE,
            COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.TITLE,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.subtitle(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUB_TITLE,
            COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.SUB_TITLE,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.errorMessages(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ERROR_MESSAGE.TEXT].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.inputs(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.EMAIL_INPUT].map(
            getRole,
          ),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.mainButtons(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON,
            COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.GOT_IT_BUTTON,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.background(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.FORGOT_PASSWORD.CONTAINER].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.forgotPassword.design.tabs.closeButtons(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.FORGOT_PASSWORD.CLOSE_BUTTON].map(getRole),
        });
    });
};
