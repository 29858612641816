import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { GetAppManifestFn, PagesBuilder } from '@wix/yoshi-flow-editor';
import adminApproval from '../components/admin-approval/.component.json';
import emailConfirmation from '../components/email-confirmation/.component.json';
import expiredToken from '../components/expired-token/.component.json';
import forgotPassword from '../components/forgot-password/.component.json';
import accessRestricted from '../components/access-restricted/.component.json';
import resetPassword from '../components/reset-password/.component.json';
import errorMessage from '../components/error-message/.component.json';
import warningMessage from '../components/warning-msg/.component.json';

import {
  APP_TOKEN,
  COMPONENT_NAMES,
  PAGES_TAB_ACTIONS,
  TPA_PAGES,
  WIDGET_HELP_IDS,
} from '../constants';
import { initLocaleKeys } from '../initLocaleKeys';
import { replaceLightboxWithAppReflow } from './lightbox';
import { ApplicationAction, PageRef } from '@wix/platform-editor-sdk';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import {
  configureForgotPasswordViewStates,
  setForgotPasswordWidgetStageBehavior,
} from '../components/forgot-password/editor.contoller';
import { setExpiredTokenWidgetStageBehavior } from '../components/expired-token/editor.controller';
import { setEmailConfirmationWidgetStageBehavior } from '../components/email-confirmation/editor.controller';
import { setAdminApprovalWidgetStageBehavior } from '../components/admin-approval/editor.controller';
import { setAccessRestrictedWidgetStageBehavior } from '../components/access-restricted/editor.controller';
import {
  configureResetPasswordViewStates,
  setResetPasswordWidgetStageBehavior,
} from '../components/reset-password/editor.controller';
import { setErrorMessageWidgetStageBehavior } from '../components/error-message/editor.controller';
import { replacePageWithAppReflow } from './page';
import { setLightboxGfpp, setMobileGfpp } from '../utils/utils';
import { setWarningMessageWidgetStageBehavior } from '../components/warning-msg/editor.controller';
import { siteMembersAuthenticationPagesAppInstalledSrc5Evid1731 } from '@wix/bi-logger-identity-data/v2';
import app from '../../.application.json';

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  context,
  flowAPI,
) => {
  const { appManifestBuilder } = options;
  const isResponsive = context.origin.type === 'RESPONSIVE';
  const isStudio = isResponsive || context.origin.subType === 'STUDIO';
  const appPopups = await editorSDK.pages.popupPages.getApplicationPopups(
    APP_TOKEN,
  );
  const appPages = await editorSDK.pages.getApplicationPages(APP_TOKEN);
  const isDevMode = await editorSDK.editor.developerMode.isEnabled(APP_TOKEN);
  const shouldEnableAppReflow = flowAPI.experiments.enabled(
    'specs.ident.shouldEnableAppReflow',
  );

  // Fetching blocks editor manifest changes
  const baseManifest = await fetchWidgetsStageData(context.initialAppData);
  console.log('🚀 ~ baseManifest:', baseManifest);
  const t = initLocaleKeys(flowAPI.translations.i18n);

  [...appPages, ...appPopups].forEach((page) => {
    editorSDK.pages.setState(APP_TOKEN, {
      state: {
        [page.tpaPageId!]: [{ id: page.id } as PageRef],
      },
    });
  });

  const viewSiteMembersAction: ApplicationAction = {
    // @ts-expect-error
    type: 'view_site_members',
  };

  const openSignUpSettingsAction: ApplicationAction = {
    // @ts-expect-error
    type: 'open_signup_settings',
  };

  appManifestBuilder
    .withJsonManifest(baseManifest as any)
    .configurePagesTab((pagesTabBuilder) => {
      return pagesTabBuilder
        .addAction({
          title: t.app.actions.manageMemberAccess(),
          icon: 'settingsAction',
          onClick: () => {
            editorSDK.editor.openDashboardPanel(APP_TOKEN, {
              url: '/site-members/access',
              closeOtherPanels: false,
            });
          },
        })
        .addAction(viewSiteMembersAction)
        .addAction(openSignUpSettingsAction)
        .addAction({
          title: t.app.actions.deleteApp(),
          icon: 'delete_icon',
          onClick: async () => {
            await editorSDK.application
              .uninstall(APP_TOKEN, { openConfirmation: true })
              .then(async () => {
                // report uninstall
                const msid = await editorSDK?.info.getMetaSiteId(APP_TOKEN);
                flowAPI.bi?.report(
                  siteMembersAuthenticationPagesAppInstalledSrc5Evid1731({
                    is_success: true,
                    app_id: app.appDefinitionId,
                    meta_site_id: msid,
                    state: 'uninstall',
                  }),
                );
                await editorSDK.editor.save(APP_TOKEN);
              })
              .catch((err) => {
                console.log('🚀 ~ file: appManifest.ts ~ .catch ~ err', err);
              });
          },
        })
        .setGrouping([
          {
            id: 'resetPassword',
            title: t.app.settings.tabs.group.resetPassword(),

            pages: [...appPopups, ...appPages]
              .filter((page) =>
                filterByIds(page.tpaPageId!, [
                  TPA_PAGES.RESET_PASSWORD,
                  TPA_PAGES.FORGOT_PASSWORD,
                  TPA_PAGES.EXPIRED_TOKEN,
                ]),
              )
              .map((page) => page.id!),
          },
          {
            id: 'emailConfirmation',
            title: t.app.settings.tabs.group.emailConfirmation(),

            pages: [...appPopups, ...appPages]
              .filter((page) =>
                filterByIds(page.tpaPageId!, [TPA_PAGES.EMAIL_CONFIRMATION]),
              )
              .map((page) => page.id!),
          },
          {
            id: 'permissions',
            title: t.app.settings.tabs.group.permissions(),

            pages: [...appPopups, ...appPages]
              .filter((page) =>
                filterByIds(page.tpaPageId!, [
                  TPA_PAGES.ACCESS_RESTRICTED,
                  TPA_PAGES.ADMIN_APPROVAL,
                ]),
              )
              .map((page) => page.id!),
          },
        ])
        .set({ displayName: t.app.settings.tabs.group.authPages() });
    })
    .configurePages((pagesBuilder) => {
      const configureInternalPage =
        (
          componentName: (typeof COMPONENT_NAMES)[keyof typeof COMPONENT_NAMES],
          tooltip: string,
          isPopup: boolean = false,
        ): Parameters<PagesBuilder['configureState']>[1] =>
        (stateBuilder) => {
          const icon = isPopup ? 'popup' : 'page';
          stateBuilder
            .set({ icon, tooltip })
            .addAction({ type: 'page_rename' });
          if (!isPopup) {
            stateBuilder.addSettingsTab(
              {
                title: t.app.settingsTab.settings.pageInfo(),
                type: 'page_info',
                url: getPanelUrl(componentName, 'PageInfo'),
              },
              { title: t.app.settingsTab.layout.title(), type: 'layout' },
            );
          }
          if (shouldEnableAppReflow && isDevMode) {
            if (isPopup) {
              stateBuilder.addAction({
                title: t.app.settings.lightbox.action.replace(),
                onClick: async (event: any) => {
                  const pageRef = event.detail.pageRef;
                  await replaceLightboxWithAppReflow(
                    editorSDK,
                    t,
                    pageRef,
                    isStudio,
                    isResponsive,
                    componentName,
                  );
                  editorSDK.document.application.reloadManifest();
                },
                icon: 'router_icon',
              });
              return;
            }
            stateBuilder.addAction({
              title: t.app.settings.page.action.replace(),
              onClick: async (event: any) => {
                const pageRef = event.detail.pageRef;
                replacePageWithAppReflow(
                  editorSDK,
                  t,
                  pageRef,
                  isStudio,
                  isResponsive,
                  componentName,
                );
              },
              icon: 'router_icon',
            });
          }
        };

      pagesBuilder.configureState(
        TPA_PAGES.FORGOT_PASSWORD,
        configureInternalPage(
          COMPONENT_NAMES.FORGOT_PASSWORD,
          t.app.widgets.forgotPassword.tooltip(),
          true,
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.ADMIN_APPROVAL,
        configureInternalPage(
          COMPONENT_NAMES.ADMIN_APPROVAL,
          t.app.widgets.pendingApproval.tooltip(),
          true,
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.EMAIL_CONFIRMATION,
        configureInternalPage(
          COMPONENT_NAMES.EMAIL_CONFIRMATION,
          t.app.widgets.emailConfirmation.tooltip(),
          true,
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.RESET_PASSWORD,
        configureInternalPage(
          COMPONENT_NAMES.RESET_PASSWORD,
          t.app.widgets.resetPassword.tooltip(),
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.EXPIRED_TOKEN,
        configureInternalPage(
          COMPONENT_NAMES.EXPIRED_TOKEN,
          t.app.widgets.expiredToken.tooltip(),
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.ACCESS_RESTRICTED,
        configureInternalPage(
          COMPONENT_NAMES.ACCESS_RESTRICTED,
          t.app.widgets.accessRestricted.tooltip(),
        ),
      );
    });

  const widgetsHelpIds = WIDGET_HELP_IDS[isStudio ? 'STUDIO' : 'CLASSIC'];
  appManifestBuilder.configureWidget(
    forgotPassword.controllerId,
    async (widgetBuilder) => {
      void configureForgotPasswordViewStates(widgetBuilder, t);
      void setForgotPasswordWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.FORGOT_PASSWORD,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    expiredToken.controllerId,
    (widgetBuilder) => {
      void setExpiredTokenWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.EXPIRED_TOKEN,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    emailConfirmation.controllerId,
    (widgetBuilder) => {
      void setEmailConfirmationWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.EMAIL_CONFIRMATION,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    adminApproval.controllerId,
    (widgetBuilder) => {
      void setAdminApprovalWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.ADMIN_APPROVAL,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    accessRestricted.controllerId,
    (widgetBuilder) => {
      void setAccessRestrictedWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.ACCESS_RESTRICTED,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    resetPassword.controllerId,
    (widgetBuilder) => {
      void configureResetPasswordViewStates(widgetBuilder, t);
      void setResetPasswordWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.RESET_PASSWORD,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    errorMessage.controllerId,
    (widgetBuilder) => {
      void setErrorMessageWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.SUBMISSION_ERROR,
        t,
      );
    },
  );

  appManifestBuilder.configureWidget(
    warningMessage.controllerId,
    (widgetBuilder) => {
      void setWarningMessageWidgetStageBehavior(
        widgetBuilder,
        widgetsHelpIds.RESEND_NOTIFICATION,
        t,
      );
    },
  );

  setLightboxGfpp(appManifestBuilder, TPA_PAGES.ADMIN_APPROVAL);
  setLightboxGfpp(appManifestBuilder, TPA_PAGES.EMAIL_CONFIRMATION);
  setLightboxGfpp(appManifestBuilder, TPA_PAGES.FORGOT_PASSWORD);

  await editorSDK.addEventListener('viewStateChanged', (event) => {
    console.log(event);
  });

  const appManifest = appManifestBuilder.build();
  console.log('🚀 ~ appManifest:', appManifest);
  await setMobileGfpp(appManifest);
  return appManifest;
};

const filterByIds = (tpaPageId: string, pageIds: string[]) => {
  return pageIds.includes(tpaPageId);
};
