import { EditorSDK, EventType } from '@wix/platform-editor-sdk';
import {
  APP_TOKEN,
  CUSTOM_ACTIONS_IDS,
  getElementsToShowRestoreNotification,
} from './constants';
import {
  getAccessRestrictedElementsData,
  getAdminApprovalElementsData,
  getEmailConfirmationElementsDataByState,
  getErrorMessagesElementsData,
  getExpiredTokenElementsData,
  getForgotPasswordElementsDataByState,
  getResetPasswordElementsDataByState,
  getWarningMessagesElementsData,
} from './editor-app/gfpp/panels/elementsData';
import { openShowHidePanel } from './editor-app/gfpp/panels/showHideElements';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { initLocaleKeys } from './initLocaleKeys';
import { siteMembersAuthenticationPagesNotificationShownSrc5Evid1846 } from '@wix/bi-logger-identity-data/v2';

export const configureEvents = (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  isResponsive: boolean,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case CUSTOM_ACTIONS_IDS.MANAGE_PAGE_PERMISSIONS: {
        editorSDK.editor.openDashboardPanel(APP_TOKEN, {
          url: '/member-permissions/roles/manage-permissions',
          closeOtherPanels: false,
        });
        break;
      }
      case CUSTOM_ACTIONS_IDS.MANAGE_MEMBER_ACCESS: {
        editorSDK.editor.openDashboardPanel(APP_TOKEN, {
          url: '/site-members/access',
          closeOtherPanels: false,
        });
        break;
      }
      case CUSTOM_ACTIONS_IDS.MANAGE_SIGNUP_SETTINGS: {
        editorSDK.editor.openDashboardPanel(APP_TOKEN, {
          url: '/member-permissions/signup-login-settings',
          closeOtherPanels: false,
        });
        break;
      }
      case CUSTOM_ACTIONS_IDS.FORGOT_PASSWORD_SHOW_HIDE_ELEMENTS: {
        const state = await editorSDK.editor.widgets.getViewState('token', {
          componentRef,
        });
        const elementsData = getForgotPasswordElementsDataByState(state, t);
        openShowHidePanel(editorSDK, componentRef, elementsData);
        break;
      }
      case CUSTOM_ACTIONS_IDS.ACCESS_RESTRICTEDS_SHOW_HIDE_ELEMENTS: {
        openShowHidePanel(
          editorSDK,
          componentRef,
          getAccessRestrictedElementsData(t),
        );
        break;
      }
      case CUSTOM_ACTIONS_IDS.RESET_PASSWORD_SHOW_HIDE_ELEMENTS: {
        const state = await editorSDK.editor.widgets.getViewState('token', {
          componentRef,
        });
        const elementsData = getResetPasswordElementsDataByState(state, t);
        openShowHidePanel(editorSDK, componentRef, elementsData);
        break;
      }
      case CUSTOM_ACTIONS_IDS.EMAIL_CONFIRMATION_SHOW_HIDE_ELEMENTS:
        const state = await editorSDK.editor.widgets.getViewState('token', {
          componentRef,
        });
        const elementsData = getEmailConfirmationElementsDataByState(state, t);
        openShowHidePanel(editorSDK, componentRef, elementsData);
        break;
      case CUSTOM_ACTIONS_IDS.ADMIN_APPROVAL_SHOW_HIDE_ELEMENTS:
        openShowHidePanel(
          editorSDK,
          componentRef,
          getAdminApprovalElementsData(t),
        );
        break;
      case CUSTOM_ACTIONS_IDS.EXPIRED_TOKEN_SHOW_HIDE_ELEMENTS:
        openShowHidePanel(
          editorSDK,
          componentRef,
          getExpiredTokenElementsData(t),
        );
        break;
      case CUSTOM_ACTIONS_IDS.ERROR_MESSAGE_SHOW_HIDE_ELEMENTS:
        openShowHidePanel(
          editorSDK,
          componentRef,
          getErrorMessagesElementsData(t),
        );
        break;
      case CUSTOM_ACTIONS_IDS.WARNING_MESSAGE_SHOW_HIDE_ELEMENTS:
        openShowHidePanel(
          editorSDK,
          componentRef,
          getWarningMessagesElementsData(t),
        );
        break;
    }
  });

  const COMPONENT_TYPES_TO_RESTORE = [
    'wixui.StylableButton',
    'StylableButton',
    'wysiwyg.viewer.components.inputs.TextInput',
    'TextInput',
    'mobile.core.components.Container',
  ];

  editorSDK.addEventListener(
    EventType.developerModeStatusChanged,
    async (event) => {
      console.log('developerModeStatusChanged', event);
      editorSDK.document.application.reloadManifest();
    },
  );

  editorSDK.addEventListener('componentDeleted', async (event) => {
    console.log('componentDeleted', event);
    const { componentRef } = event.detail;
    const componentData = (await editorSDK.components.data.get(APP_TOKEN, {
      componentRef,
    })) as any;
    const elementsToShowRestoreNotification =
      getElementsToShowRestoreNotification(t);
    if (
      !COMPONENT_TYPES_TO_RESTORE.includes(componentData.type) &&
      !elementsToShowRestoreNotification.has(componentData.label)
    ) {
      return;
    }
    editorSDK.editor.showUserActionNotification(APP_TOKEN, {
      message: t.app.warning.restoreElementText(),
      // @ts-expect-error
      type: 'warning',
      link: {
        caption: t.app.warning.restoreElementLink(),
        onClick: async () => {
          await editorSDK.document.components.refComponents.expandReferredComponent(
            APP_TOKEN,
            { componentRef },
          );
        },
      },
    });
    const pageRef = await editorSDK.components.getPage(APP_TOKEN, {
      componentRef,
    });
    const pageData = await editorSDK.pages.getPageData(APP_TOKEN, {
      pageRef,
    });
    const meta_site_id = await editorSDK.info.getMetaSiteId(APP_TOKEN);
    flowAPI.bi?.report(
      siteMembersAuthenticationPagesNotificationShownSrc5Evid1846({
        editorType: isResponsive ? 'studio' : 'classic',
        text: t.app.warning.restoreElementText(),
        page_name: pageData.title,
        meta_site_id,
      }),
    );
  });
};
